h2 {
  font-weight:200;
}



.buttonText{
  margin-top:auto;
  margin-bottom:auto;
  color:white;
  font-size:3em;
  height:100%;
}

.textCenter{
  text-align:center;
  margin-left:auto;
  margin-right:auto;
  margin-top:auto;
  margin-bottom:auto;
}

@media(max-width:1000px){
  #statusBarf{
    display:grid;
    grid-template-columns:30% 1fr 50%;
    grid-template-rows:1fr;
    height:100%;
    background-color:white;
  }

  
  .timerInterval-number{
    width:80%;
    margin-right:auto;
    margin-left:auto;
  }

  .changeIntervalButton{
    height:100%;
    margin-left: 10%;
    margin-right:10%;
    background-color:lightslategrey;
    text-align:center;
    vertical-align:middle;
  }


  #timerIntervalControlPannel{
    display:grid;
    grid-template-rows: 2fr 3fr 2fr;
    margin-top: 3%;
    min-height:60px;
    height: 11%;
    background-color:white;
    text-align:center;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  #insideControlPannel{
    margin-top:auto;
    margin-bottom:auto;
    text-align:center;
  }

  .InputPannel{
    margin-top: auto;
    margin-bottom: auto;
    margin-left:auto;
    margin-right:auto;
    display:grid;
    width:80%;
    grid-template-columns: 1fr 1fr;
  }

  #loginStatus {
    text-align:right;
    font-family: SourceSansPro-Regular, Source Sans Pro;
    fill: #43425d;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1rem;
  }

  #qwer{
    height:100%;
  }

  #smallLogoFile{
    margin-left:auto;
    margin-right:auto;
    margin-top:auto;
    margin-bottom:auto;
    width:80%;
  }

  #led1_down{
    width:100%;
    height:100%;
  }

  #led1_up{
    width: 100%;
    height:100%;
  }


  .ledUpDownGrid{
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr;
    display:grid;
    margin-left:auto;
    margin-right:auto;
    margin-top:3%;
  }


  #squaredlogo{
    height:100%;
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:auto;
  }

  #logoarea{
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:auto;
  }

  #information_mobile{
    display:grid;
    grid-template-rows: 1.5fr 1fr;
    background-color:white;
    width: 100%;
    margin-top:3%;
    height:44%;
  }

  #information{
    display:none;
  }


  #background_image{
    width:100%;
    height:auto;
  }

  #instructionarea{
    text-align:left;
    height: auto;
  }


  .ledButtonIcon {
    margin-left:auto;
    margin-right:auto;
    width:100%;
  }

  .ledStopButtonIcon{
    margin-left:auto;
    margin-right:auto;
    width:100%;
    margin-top:3%;
  }

  #sangsangfarmAtDashboard{
    margin-top:4%;
  }

  #dashboardGrid{
    display:block;
  }

  #dashboardelement {
    height:100%;
  }

  #dashboardBoard{
    background-color:#F2F2F8;
    display:grid;
    height:100%;
    grid-template-rows:1fr 20fr;
  }

  #blockingMask {
    width:100%;
    margin-top:3%;
  }

  #rightPannel{
    width:90%;
    margin-right:auto;
    margin-left:auto;
  }
  #leftPannel{
    width:90%;
    margin-right:auto;
    margin-left:auto;
  }

  #yangek_control_info_grid{
    margin-top: 3%;
    margin-right:auto;
    margin-left:auto;
    display:grid;
    width:100%;
    grid-template-columns:4.2fr 1fr;
    grid-template-rows:1fr;
  }

  #gupsuButton{
    width:100%;
    margin-top:3%;
  }

  #pyesuButton{
    width:100%;
    margin-top:3%;
  }

  #nutrientDeviceInfo{
    margin-top:auto;
    margin-bottom:auto;
    width:100%;
  }

  #ledDeviceInfo{
    width:100%;
    margin-right:auto;
    margin-left:auto;
    margin-top:3%;
  }

  #nutrientOnOff{
    margin-top:auto;
    margin-bottom:auto;
    margin-left:8%;
    height:100%;
  }

  #mobile{
    display:block;
  }

  #desktop{
    display:none;
  }

  #desktop_ledpannel{
    display:none;
  }
  #mobileGupPyeSu{
    display:block;
  }
}

@media(min-width:1000px){
  #desktop_ledpannel{
    display:block;
  }
  #mobileGupPyeSu{
    display:none;
  }
  #mobile{
    display:none;
  }
  
  .timerInterval-number{
    width:80%;
  }

  .changeIntervalButton{
    height:100%;
    margin-left: 10%;
    margin-right:10%;
    background-color:lightslategrey;
    text-align:center;
    vertical-align:middle;
  }

  .InputPannel{
    margin-top: auto;
    margin-bottom: auto;
    display:grid;
    grid-template-columns: 1fr 1fr;
  }

  #desktop{
    display:block;
  }
  #statusBarf{
    display:grid;
    grid-template-columns:10% 1fr 15%;
    grid-template-rows:1fr;
    height:100%;
    background-color:white;
  }

  #loginStatus {
    text-align:right;
    font-family: SourceSansPro-Regular, Source Sans Pro;
    fill: #43425d;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1rem;
  }

  #qwer{
    height:100%;
  }

  #smallLogoFile{
    margin-left:auto;
    margin-right:auto;
    margin-top:auto;
    margin-bottom:auto;
    width:80%;
  }

  #led1_down{
    width:100%;
    height:100%;
  }

  #led1_up{
    width: 100%;
    height:100%;
  }


  .ledUpDownGrid{
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr;
    display:grid;
    margin-left:auto;
    margin-right:auto;
    margin-top:3%;
  }

  #timerIntervalControlPannel{
    display:grid;
    grid-template-columns: 2fr 3fr 2fr;
    margin-top: 3%;
    min-height:60px;
    height: 11%;
    background-color:white;
    text-align:center;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  #insideControlPannel{
    margin-top:auto;
    margin-bottom:auto;
    text-align:center;
  }

  #squaredlogo{
    height:100%;
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:auto;
  }

  #logoarea{
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
    margin-right:auto;
  }

  #information{
    display:grid;
    grid-template-rows: 1.5fr 1fr;
    background-color:white;
    width: 100%;
    margin-top:3%;
    height:30%;
  }

  #information_mobile{
    display:none;
  }

  #background_image{
    width:100%;
    height:auto;
  }

  #instructionarea{
    text-align:left;
    padding-left:10%;
    height: auto;
  }


  .ledButtonIcon {
    margin-left:auto;
    margin-right:auto;
    width:100%;
  }

  .ledStopButtonIcon{
    margin-left:auto;
    margin-right:auto;
    width:100%;
    margin-top:3%;
  }

  #sangsangfarmAtDashboard{
    margin-top:4%;
  }

  #dashboardGrid{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr;
  }

  #dashboardelement {
    height:100%;
  }

  #dashboardBoard{
    background-color:#F2F2F8;
    display:grid;
    height:100%;
    grid-template-rows:1fr 10fr;
  }

  #blockingMask {
    width:100%;
    margin-top:3%;
  }

  #rightPannel{
    width:90%;
    margin-right:auto;
    margin-left:auto;
  }
  #leftPannel{
    width:90%;
    margin-right:auto;
    margin-left:auto;
  }

  #yangek_control_info_grid{
    margin-top: 3%;
    margin-right:auto;
    margin-left:auto;
    display:grid;
    width:100%;
    grid-template-columns:4.2fr 1fr;
    grid-template-rows:1fr;
  }

  #gupsuButton{
    width:100%;
    margin-top:3%;
  }

  #pyesuButton{
    width:100%;
    margin-top:3%;
  }

  #nutrientDeviceInfo{
    margin-top:auto;
    margin-bottom:auto;
    width:100%;
  }

  #ledDeviceInfo{
    width:100%;
    margin-right:auto;
    margin-left:auto;
    margin-top:3%;
  }

  #nutrientOnOff{
    margin-top:auto;
    margin-bottom:auto;
    margin-left:8%;
    height:100%;
  }
}