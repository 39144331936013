
@media(max-width:1000px){
  #userIdFormComponenet{
      margin-top:13%;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
  }

  #background{
    display:none;
  }

  .inputFormLine{
      margin-top: 3%;
  }

  #enterButton{
    margin-top:10%;
    margin-bottom:5%;
  }

  #sangsangfarm{
    display:none;
  }

  #active {
    margin-top:10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:1%;
    align-content:center;
    text-align:center;
    align-items: center;
    width:80%
  }

  #form{
    height:60%;
    display:block;
    align-content:center;
    text-align:center;
    align-items: center;
  }

  #loginButtonElement{
    margin-top:10%;
  }

  #grid {
    display:block;
  }

  #logo {
    width:40%;
    transform:translate(1060 152);
    margin-left:auto;
    margin-right:auto;
  }

  .cls-7 {
    font-family: SourceSansPro-Regular, Source Sans Pro;
  }
  .cls-7 {
    fill: #43425d;
  }


  .cls-7 {
    font-size: 1rem;
    text-align:center;
    display:block;
  }

  #loginAlert{
    color:red;
  }
}

@media(min-width:1000px){
  #userIdFormComponenet{
      margin-top:13%;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
  }

  .inputFormLine{
      margin-top: 3%;
  }

  #enterButton{
    margin-top:10%;
    margin-bottom:5%;
  }

  #active {
    margin-top:30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:1%;
    align-content:center;
    text-align:center;
    align-items: center;
    width:80%
  }

  #form{
    height:60%;
    display:block;
    align-content:center;
    text-align:center;
    align-items: center;
  }

  #loginButtonElement{
    margin-top:10%;
  }

  #grid {
    display:grid;
    grid-template-columns:1fr 1fr;
  }

  #logo {
    width:40%;
    transform:translate(1060 152);
    margin-left:auto;
    margin-right:auto;
  }

  .cls-7 {
    font-family: SourceSansPro-Regular, Source Sans Pro;
  }
  .cls-7 {
    fill: #43425d;
  }


  .cls-7 {
    font-size: 1rem;
    text-align:center;
    display:block;
  }

  #loginAlert{
    color:red;
  }
}